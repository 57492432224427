import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import style from './index.style';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Autoplay,
  FreeMode,
  HashNavigation,
  Navigation,
  Pagination,
} from 'swiper';
import { getFileAddress } from '../../../../tools/utils';
import { Link } from 'components/utils/Link';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function BannerSection({ banner }) {
  const { TRANSLATIONS, lang } = useTranslation();
  const { isMobile, isTablet } = useResponsive();
  const isSmallDevice = isMobile || isTablet;
  const router = useRouterWithLang();
  const PERMISSIONS = useSelector((state) => state.permissions);
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];

  const onClickItem = useCallback(
    (pic) => {
      const query = pic.link;
      const link = PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?${query}`
        : `/product/?${query}`;
      router.push(link);
    },
    [PERMISSIONS.IS_PRIVATE, router, userCatalog?._id]
  );

  const endDate = new Date(
    banner?.endDate.toString().split('.')[0] + banner?.timeZone
  ).getTime();
  let now = new Date().getTime();
  const inactive =
    (now > endDate && banner?.status === true) || banner.status === false;

  return (
    <div className={`banner-section ${inactive && 'inactive'}`}>
      {inactive && (
        <div className={'message-admin'}>Visibile solo da ADMIN</div>
      )}
      <div
        className={`banner-first-section  ${
          banner?.textWhite ? 'white-text' : 'text-default'
        }`}
        style={{ background: `${banner?.background}` }}
      >
        <div className={'home-card'}>
          <div
            className={`d-f mb-md ${banner.reverse && 'reverse'} ${
              isSmallDevice ? 'fd-c g-10 img-banner-mobile' : 'g-30'
            }`}
          >
            {banner.bannerActive && (
              <img
                src={banner?.banner}
                className={
                  banner[`title_${lang}`] || banner.brand
                    ? 'img-banner'
                    : 'f-1 img-banner-wide'
                }
                alt={'banner home'}
                onClick={() => onClickItem(banner)}
              />
            )}
            {(banner?.brand || banner[`title_${lang}`]) && (
              <div className={'d-f fd-c g-5 f-1'}>
                <h2>{banner[`title_${lang}`] || banner.brand}</h2>
                <h5>{banner[`sub_${lang}`]}</h5>
                <div className={'d-f ai-c g-5 mt-xl'}>
                  <span
                    className={''}
                    onClick={() => onClickItem(banner)}
                    style={{
                      cursor: 'pointer',
                      padding: 12,
                      border: '1px solid',
                      textTransform: 'uppercase',
                    }}
                  >
                    {TRANSLATIONS.home.viewAll}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {banner?.carouselActive && banner?.productCarousel?.length > 0 && (
        <div
          className={`banner-second-section ${
            banner?.textWhite ? 'white-text' : 'text-default'
          }`}
          style={{
            background: banner?.backgroundCarousel
              ? `${banner.backgroundCarousel}`
              : `${banner.background}`,
          }}
        >
          <div className={'home-card'}>
            <div className={'mt-md mb-md'}>
              <Swiper
                spaceBetween={!isMobile && 12}
                slidesPerGroup={1}
                className="mySwiper"
                slidesPerView={'auto'}
                loopFillGroupWithBlank={true}
                freeMode={true}
                navigation={!isMobile}
                scrollbar={{ draggable: true }}
                modules={[
                  Pagination,
                  Autoplay,
                  Navigation,
                  FreeMode,
                  HashNavigation,
                ]}
              >
                {banner?.productCarousel
                  ?.slice()
                  .sort((a, b) => {
                    if (a.priority > b.priority) return -1;
                    if (a.priority < b.priority) return 1;
                    return 0;
                  })
                  .map((pic, index) => (
                    <SwiperSlide
                      key={index}
                      index={index}
                      className="carousel-slide"
                    >
                      <Link href={pic?.link}>
                        <div className={'card-product d-f fd-c'}>
                          <img
                            className="slider-image"
                            src={pic.image ? pic.image : getFileAddress(pic)}
                            alt={pic?.title}
                          />
                          <div
                            className={
                              'mt-md ta-c d-f fd-c g-5 card-product-info'
                            }
                          >
                            <div className={'d-f g-5 jc-c ai-c fd-c fw-w'}>
                              <p className={'title'}>{pic.brand}</p>
                              <p className={'subtitle'}>
                                {pic[`title_${lang}`]}
                              </p>
                            </div>
                            <div className={'d-f g-5 ai-c jc-c'}>
                              <p className={'price'}>{pic?.price} €</p>
                              {pic?.promoPrice && (
                                <p className={'promo-price'}>
                                  {pic?.promoPrice} €
                                </p>
                              )}
                            </div>
                            {pic?.cashback && (
                              <div className={'d-f fw-w ai-c'}>
                                <div
                                  className={'cashback'}
                                  style={{
                                    backgroundColor: `${
                                      banner.colorCashback &&
                                      banner.colorCashback
                                    }`,
                                  }}
                                >
                                  <span
                                    className={'cashback-amount'}
                                    style={{
                                      color: `${
                                        banner.colorTextCashback &&
                                        banner.colorTextCashback
                                      }`,
                                    }}
                                  >
                                    Cashback: {pic?.cashback} €
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
      <style jsx>{style}</style>
    </div>
  );
}
