import css from 'styled-jsx/css';

export default css`
  .home-card__header {
    margin-bottom: 1em;
  }
  .home-card__slider {
    overflow: visible;
  }
  .home-card__slider :global(.swiper-wrapper) {
    // max-height: 430px;
  }
  .home-card__slider :global(.swiper-button-prev),
  .home-card__slider :global(.swiper-button-next) {
    top: 0;
    height: 100%;
    padding: 4px;
    transition: 0.3s;
  }
  .home-card__slider :global(.swiper-button-prev) {
    left: 0px;
  }
  .home-card__slider :global(.swiper-button-next) {
    right: 0px;
  }
  .home-card__slider :global(.swiper-button-next:after) {
    font-size: 24px !important;
    color: black;
    font-weight: 600;
  }
  .home-card__slider :global(.swiper-button-prev:after) {
    font-size: 24px !important;
    color: black;
    font-weight: 600;
  }
  :global(.swiper-horizontal > .swiper-scrollbar) {
    visibility: hidden;
  }
  .home-card__slider :global(.swiper-slide) {
    display: inherit;
    max-width: 100% !important;
  }
  @media screen and (max-width: 768px) {
    .home-card__slider {
      overflow: visible;
    }
  }
`;
