import css from 'styled-jsx/css';

export default css`
  :global(.home-container) {
    position: relative;
    background: var(--background-home-2);
  }
  :global(.main),
  :global(main) {
    padding: 0px !important;
    margin: 0px;
    box-sizing: border-box;
    background: white;
  }
  :global(.content) {
    max-width: none !important;
  }
  :global(.home-card) {
    max-width: 1300px;
    margin: auto;
    padding: 0px 12px;
  }
  :global(.home-card h4) {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 500;
    -webkit-letter-spacing: 0.5px;
    -moz-letter-spacing: 0.5px;
    -ms-letter-spacing: 0.5px;
    letter-spacing: 0.5px;
  }
  :global(.home-card__header) {
    border-box: box-sizing;
    width: 100%;
    max-width: 1900px;
    margin: auto;
  }
  :global(.home-card__header) {
    box-sizing: border-box;
    width: 100%;
    margin: auto;
  }
  :global(.home-card__header .swiper-slide) {
    width: 100% !important;
  }

  :global(.carousel-slide img) {
    cursor: pointer;
    width: 100%;
    transition: 0.3s;
  }
  @media only screen and (max-width: 768px) {
    :global(.home-card) {
      margin: auto;
      padding: 0;
    }
  }
  :global(.swiper-horizontal > .swiper-scrollbar) {
    visibility: hidden;
  }
  }
`;
