import useTranslation from 'hooks/useTranslation';
import { useState } from 'react';
import { useEffect } from 'react';
import { runTimer } from 'tools/utils';

import style from './index.style';
import useResponsive from '../../../../hooks/useResponsive';

export default function Timer({ banner }) {
  const { TRANSLATIONS } = useTranslation();
  const { isMobile } = useResponsive();
  const timeZone = banner?.timeZone || [];
  const endDate = banner?.endDate || [];

  const [timeLeft, setTimeLeft] = useState(runTimer(endDate, timeZone));
  useEffect(() => {
    const timerRef = setTimeout(() => {
      setTimeLeft(runTimer(endDate, timeZone));
    }, 1000);

    return () => clearTimeout(timerRef);
  });

  return (
    <div
      className={` ${
        isMobile ? 'wrapper-timer-mobile' : 'wrapper-timer'
      } d-f g-5 ${timeLeft.days === undefined ? ' d-n' : ''}`}
    >
      <div className="time d-f ai-c jc-c fd-c">
        {timeLeft.days}
        <span>{TRANSLATIONS.promo.timer.days}</span>
      </div>
      <div className="time d-f ai-c jc-c fd-c">
        {timeLeft.hours}
        <span>{TRANSLATIONS.promo.timer.hours}</span>
      </div>
      <div className="time d-f ai-c jc-c fd-c">
        {timeLeft.minutes}
        <span>{TRANSLATIONS.promo.timer.minutes}</span>
      </div>
      <div className="time d-f ai-c jc-c fd-c">
        {timeLeft.seconds} <span>{TRANSLATIONS.promo.timer.seconds}</span>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}
