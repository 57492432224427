import { closeHowToStart } from 'actions/ui';
import ConfirmEmailMessage from 'components/utils/ConfirmEmailMessage';

import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import Catalog from './Catalog';
import Header from './Header';
import style from './index.style';
import { getLocalStorage } from '../../tools/localStorage';
import useResponsive from 'hooks/useResponsive';
import { useIsEmbedded } from 'hooks/useIsEmbedded';
import Dropshipping from './Dropshipping';
import Category from './Category';
import { useEffect } from 'react';
import CashbackSection from './CashbackSection';
import BannersHome from './BannersHome';
import useTranslation from '../../hooks/useTranslation';

export default function Home() {
  const dispatch = useDispatch();
  const { TRANSLATIONS, locale } = useTranslation();
  const { isMobile } = useResponsive();
  const isEmbedded = useIsEmbedded();
  const userEmail = useSelector((s) => s.user.email);
  const userCatalogs = useSelector((s) => s.user.userCatalogs);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const UI = useSelector((state) => state?.UI) || [];
  const message = UI?.show_confirmation_email_message;
  const confirmationEmail = UI?.confirmation_email;
  useEffect(() => {
    const shouldDisableTutorial =
      getLocalStorage(`disableTutorial/${userEmail}`) === 'true';
    if (shouldDisableTutorial) {
      dispatch(closeHowToStart());
    }
  }, [dispatch, userEmail]);

  useEffect(() => {
    setTimeout(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'lead',
        },
      });
    }, 1000);
  }, []);

  const freeUser = PERMISSIONS.IS_FREE_USER;

  return (
    <div className={`home-container relative`}>
      {isMobile && message && false && (
        <ConfirmEmailMessage userEmail={confirmationEmail} />
      )}
      {locale === 'it_IT' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <img
            src={
              isMobile
                ? '/images/home/banner-trustpilot-mobile.jpg'
                : '/images/home/banner-trustpilot-desktop.jpg'
            }
            alt={'banner trustpilot'}
          />
        </div>
      )}
      {!isEmbedded && <Header />}
      {!PERMISSIONS.IS_PRIVATE && isMobile && false && (
        <Catalog catalogs={userCatalogs} />
      )}
      {PERMISSIONS.IS_ONLY_SOCIAL && false && (
        <CashbackSection catalogs={userCatalogs} />
      )}
      <BannersHome />
      <Category />
      {freeUser && <Dropshipping />}
      <style jsx>{style}</style>
    </div>
  );
}
