import { useIsEmbedded } from 'hooks/useIsEmbedded';
import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback } from 'react';
import Timer from './Timer';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import style from './index.style';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function Header() {
  const { lang } = useTranslation();
  const { isMobile, isTablet } = useResponsive();
  const router = useRouterWithLang();
  const PERMISSIONS = useSelector((state) => state.permissions);
  const setting =
    useSelector((state) => state.setting)?.find(
      (setting) => setting.type === 'banners'
    )?.body?.banners || {};
  const banners = Object.values(setting);
  const _banners = Object.entries(banners)
    ?.map((banner) => banner?.[1])
    ?.filter((banner) => {
      let endDate = new Date(
        banner?.endDate.toString().split('.')[0] + banner?.timeZone
      ).getTime();
      let startDate = new Date(
        banner?.startDate.toString().split('.')[0] + banner?.timeZone
      ).getTime();
      let now = new Date().getTime();
      return now >= startDate && now < endDate && banner?.status === true;
    });

  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];
  const isEmbedded = useIsEmbedded();
  const onClickItem = useCallback(
    (pic) => {
      router.push(pic?.link);
    },
    [router]
  );

  return (
    <div className="home-card__header relative">
      {!isEmbedded && (
        <div className={`home-card__slider relative`}>
          <Swiper
            slidesPerView={1}
            spaceBetween={8}
            slidesPerGroup={1}
            centeredSlides={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {_banners?.map((banner, index) => (
              <SwiperSlide
                key={banner}
                index={index}
                className="carousel-slide"
              >
                {banner?.timer !== false && (
                  <div>
                    <Timer banner={banner} />
                  </div>
                )}
                <img
                  className="slider-image"
                  src={
                    isMobile
                      ? banner[`littleBanner${lang}`]
                      : banner[`bigBanner${lang}`]
                  }
                  alt={banner?.alt}
                  onClick={() => onClickItem(banner, index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <style jsx>{style}</style>
    </div>
  );
}
