import { closeConfirmedMessage } from 'actions/ui';
import { resendEmail } from 'actions/user';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import useResponsive from '../../hooks/useResponsive';
import useTranslation from '../../hooks/useTranslation';
import Button from './Button';
import Icon from './Icon';

function ConfirmEmailMessage({ userEmail }) {
  const { TRANSLATIONS } = useTranslation();
  const { isMobile } = useResponsive();
  const isSmallDevice = isMobile;

  const dispatch = useDispatch();
  const closeConfirmMessage = useCallback(() => {
    dispatch(closeConfirmedMessage());
  }, [dispatch]);

  const sendEmail = useCallback(async () => {
    try {
      await dispatch(resendEmail(userEmail));
    } catch (e) {
      console.log('error resend email', e);
    } finally {
      dispatch(closeConfirmedMessage());
    }
  }, [dispatch, userEmail]);

  const [open, setOpen] = useState();
  const openMessage = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <>
      <div className={`confirm-email fd-c ${open ? 'confirm-open g-10' : ''}`}>
        <div className={`d-f ai-c jc-sb g-10 w-100`}>
          <div className={`confirm-email-title w-100 d-f ai-fs g-5 f-1`}>
            <Icon type={'mail'} />
            <p>{TRANSLATIONS.home.confirmEmail}</p>
          </div>
          <div className="d-f confirm-email-icon g-5">
            {isSmallDevice ? (
              <Icon
                type={open ? 'arrow_drop_down' : 'arrow_drop_up'}
                onClick={openMessage}
              />
            ) : (
              <Icon
                type={open ? 'arrow_right' : 'arrow_left'}
                onClick={openMessage}
              />
            )}
          </div>
        </div>
        <div>
          {open && (
            <div className="d-f confirm-email-p g-20 fd-c">
              <p>
                {TRANSLATIONS.home.emailConfirm} {TRANSLATIONS.home.checkEmail}
              </p>
              <div className="g-5 d-f">
                <Button
                  className={`small btn-white`}
                  label={TRANSLATIONS.home.resendEmail}
                  onClick={sendEmail}
                />
                <Button
                  className={`small action-trasparent`}
                  label={TRANSLATIONS.home.close}
                  onClick={closeConfirmMessage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .back-button {
          font-size: 14px;
          font-weight: 500;
          line-height: 35px;
          margin-bottom: 0.5em;
          cursor: pointer;
          width: 100px;
        }
        .back-button :global(.icon) {
          margin-right: 10px;
        }
        .confirm-email-message-box .button {
          margin-top: 16px;
        }
        .confirm-email {
          display: flex;
          position: absolute;
          right: 0;
          top: -1px;
          border-radius: 10px;
          padding: 0px 8px;
          transition: all 0.3s;
          max-width: 300px;
          background: #fffbea;
          border: solid thin var(--warning);
          color: var(--grey-dark);
        }
        .confirm-email-title p {
          font-size: 14px;
          font-weight: 600;
        }
        .confirm-email-p p {
          font-size: 14px;
          font-weight: 400;
        }
        .confirm-email-p :global(.button) {
          color: var(--grey-dark);
        }
        .confirm-email-title :global(.icon) {
          font-size: 18px;
        }
        .confirm-email-icon :global(.icon:first-child) {
          font-size: 24px;
          padding: 6px;
        }
        .confirm-open {
          transition: all 0.3s;
          height: auto;
          z-index: 5;
          padding-bottom: 8px;
        }
        .confirm-email-icon :global(.icon):hover {
          background: #ccc3;
          -webkit-transition: 0.3s ease-in;
          transition: 0.3s ease-in;
          cursor: pointer;
          border-radius: 50px;
        }

        @media only screen and (max-width: 768px) {
          .confirm-email {
            position: fixed;
            bottom: 5px;
            left: 50%;
            transform: translate(-50%, 0px);
            z-index: 6;
            top: auto;
            max-width: 100% !important;
            width: max-content;
            box-shadow: var(--shadow-light-hover);
          }
          .confirm-open {
            width: 100%;
            transition: all 0.3s;
            bottom: 0px;
            border-radius: 10px 10px 0px 0px;
          }
          .confirm-email-title p {
            font-size: 13px;
          }
        }
      `}</style>
    </>
  );
}

export default ConfirmEmailMessage;
