import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import style from './index.style';
import Icon from '../../utils/Icon';
import { useMemo } from 'react';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function Dropshipping() {
  const { TRANSLATIONS, translationsParameters, locale } = useTranslation();
  const { isMobile } = useResponsive();
  const router = useRouterWithLang();
  const linkReseller = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://bdroppy.com/it/area-rivenditori/'
        : locale === 'fr_FR'
        ? 'https://bdroppy.com/fr/espace-revendeur/'
        : locale === 'es_ES'
        ? 'https://bdroppy.com/es/zona-de-distribuidores/'
        : 'https://bdroppy.com/reseller-area/',
    [locale]
  );
  const linkAffiliation = useMemo(
    () =>
      locale === 'it_IT'
        ? 'https://bdroppy.com/it/programma-di-affiliazione/'
        : locale === 'fr_FR'
        ? 'https://bdroppy.com/fr/programme-affiliation/'
        : locale === 'es_ES'
        ? 'https://bdroppy.com/es/programa-de-afiliados/'
        : 'https://bdroppy.com/affiliate-program/',
    [locale]
  );

  return (
    <div className={`dropshipping`}>
      <div className={`home-card d-f g-20 ${isMobile && 'fd-c'}`}>
        <div className={'d-f fd-c f-1'}>
          <a href={linkReseller} target={'_blank'} rel="noreferrer">
            <div className={`d-f g-20 ${isMobile && 'fd-c'}`}>
              <div className={'dropshipping__reseller'}>
                <div className="dropshipping__reseller-card">
                  <h6>
                    {translationsParameters(TRANSLATIONS.home.resellerText, [
                      // eslint-disable-next-line react/jsx-key
                      <span>{TRANSLATIONS.home.resellerText2}</span>,
                    ])}
                  </h6>
                  <div className={'icon-arrow'}>
                    <Icon type={'arrow-right'} />
                  </div>
                </div>
                {!isMobile && (
                  <img
                    src={'./images/home/new/reseller.png'}
                    alt={'reseller'}
                  />
                )}
              </div>
            </div>
          </a>
        </div>
        <div className={'d-f fd-c f-1'}>
          <a href={linkAffiliation} target={'_blank'} rel="noreferrer">
            <div className={`d-f g-20 w-100 ${isMobile && 'fd-c'}`}>
              <div className={'dropshipping__affiliation'}>
                <div className="dropshipping__affiliation-card">
                  <h6>
                    {translationsParameters(TRANSLATIONS.home.affiliationText, [
                      // eslint-disable-next-line react/jsx-key
                      <span>{TRANSLATIONS.home.affiliationText2}</span>,
                    ])}
                  </h6>
                  <div className={'icon-arrow'}>
                    <Icon type={'arrow-right'} />
                  </div>
                </div>
                {!isMobile && (
                  <img
                    src={'./images/home/new/affiliation.png'}
                    alt={'affiliation'}
                  />
                )}
              </div>
            </div>
          </a>
        </div>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}
