import css from 'styled-jsx/css';

export default css`
  .tips {
    z-index: 1;
  }
  .tips :global(.swiper-button-next:after),
  .tips :global(.swiper-button-prev:after) {
    font-size: 22px;
    font-weight: 600;
    color: black;
  }
  .tips :global(.swiper-button-next) {
    right: 0px;
    width: 30px;
    height: 35px;
    border: solid thin var(--grey-light);
    background: white;
  }
  .tips :global(.swiper-button-prev) {
    left: 0px;
    width: 30px;
    height: 35px;
    border: solid thin var(--grey-light);
    background: white;
  }
  .tips :global(.swiper-slide) {
    display: inherit;
    width: auto !important;
    max-width: calc(33.2% - 6px);
    transition: 0.4s;
  }
  .tips :global(.swiper-slide:hover) {
    transform: translateY(-2px);
    filter: brightness(1.05);
  }
  :global(.swiper-pagination-bullet-active) {
    opacity: 1 !important;
    width: 30px !important;
    background: var(--accent-dark) !important;
  }
  :global(.swiper-pagination-bullet) {
    width: 12px;
    height: 6px;
    display: inline-block;
    border-radius: 10px;
    background: var(--background);
    opacity: 0.5;
  }
  .tips__best-sellers {
    background: var(--background-home);
    padding: 4em 0em;
  }
  .tips__unmissable {
    padding: 4em 0em;
    background: linear-gradient(transparent, var(--background-home-2));
  }
  .tips__unmissable h4,
  .tips__best-sellers h4 {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .tips__unmissable img,
  .tips__best-sellers img {
    border-radius: 8px;
    box-shadow: var(--shadow-light);
  }
  .tips__unmissable-card-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #fff5;
    height: 35px;
    width: 35px;
    border-radius: 100px;
  }
  @media only screen and (max-width: 600px) {
    .tips__best-sellers {
      padding: 2em 0em !important;
    }
    .tips__unmissable {
      padding: 2em 1em;
    }
    .padding-h4 {
      padding: 0em 1em;
    }
    .tips :global(.swiper-slide) {
      max-width: calc(100% - 48px) !important;
      margin: 0px 6px;
    }
    .tips :global(.swiper-slide:first-child) {
      margin-left: 20px;
    }
    .tips :global(.swiper-slide:last-child) {
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    .tips__best-sellers,
    .tips__unmissable {
      padding: 2em 1em;
    }
    .tips :global(.swiper-slide) {
      display: inherit;
      width: auto !important;
      max-width: calc(50% - 6px);
    }
  }
`;
