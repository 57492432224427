import css from 'styled-jsx/css';

export default css`
  .list_home {
    position: relative;
    z-index: 1;
  }
  .home-tutorial-container {
    padding: 16px;
    background: var(--background-utils);
    border-radius: 6px;
    box-shadow: var(--shadow-light);
  }
  .catalog {
    box-shadow: var(--shadow-light);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    margin: 8px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    background: var(--background-catalog);
    width: 100%;
  }
  .catalog-name p {
    max-width: 140px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .catalog-name p:first-child {
    font-weight: 700;
    margin-right: 8px;
  }
  .catalog-name,
  .catalog-img {
    display: flex;
    align-items: center;
  }
  .catalog-name {
    display: flex;
  }
  .catalog:hover {
    box-shadow: var(--shadow-light);
    cursor: pointer;
    transform: translateY(-2px);
    transition: all 0.3s ease;
  }
  .no-catalog {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  .catalog-img-wrapper {
    border-radius: 5px;
    background: var(--background-catalog-img);
    min-width: 100px;
    min-height: 40px;
    display: flex;
    align-items: center;
  }
  .catalog-img-wrapper p {
    color: var(--grey-dark);
    font-size: 14px;
    display: flex;
    padding: 0px 2px;
    margin: auto;
  }
  .catalog-img-wrapper img {
    max-height: 45px;
    margin: auto;
    padding: 0.1em;
  }
  .p-a {
    position: absolute;
    width: 100%;
    bottom: 0px;
  }
  .no-catalog h4 {
    margin-bottom: var(--margin-sm);
  }
  .catalog-name-product {
    font-size: 14px;
    color: var(--grey-light);
    font-weight: 400;
  }
  .catalog-name-title {
    font-size: 16px;
    font-weight: 500;
  }
  @media (min-width: 768px) {
    .no-catalog {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      align-items: center;
    }
    .catalog-img {
      display: flex;
      align-items: center;
    }
    .no-catalog h4 {
      margin-bottom: 0;
    }
    .card-gradient {
      height: 100%;
      min-height: 280px;
    }
    .catalog-name p {
      max-width: 245px;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .catalog-img-wrapper {
      width: 110px;
      height: 45px;
      border-radius: 5px;
      background: var(--background-catalog-img);
      color: var(--grey-dark);
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .catalog-img-wrapper p {
      font-size: 14px;
    }
    .catalog-img-wrapper img {
      max-height: 42px;
      margin: auto;
      display: flex;
      align-items: center;
      height: 42px;
    }
  }
  .padding {
    padding: 1em;
  }
`;
