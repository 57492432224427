import css from 'styled-jsx/css';

export default css`
  .banner-section {
  }
  .inactive {
    border: dotted 3px red;
  }
  .message-admin {
    background: red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    font-size: 15px;
    font-weight: 600;
  }
  .banner-first-section {
    padding: 2em 0em 0.5em;
  }
  .banner-second-section {
    padding: 0.5em 0em 2em;
  }
  .card-product {
    max-width: 240px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;
  }
  .card-product-info {
    padding: 1em;
    align-items: center;
  }
  .title {
    font-size: 15px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 400;
  }
  .price {
    font-weight: 600;
  }
  .promo-price {
    font-size: 14px;
    text-decoration: line-through;
  }
  .price span,
  .promo-price span {
    font-size: 12px;
  }
  .white-text :global(h2),
  .white-text :global(h3),
  .white-text :global(h5),
  .white-text :global(a),
  .white-text :global(span),
  .white-text :global(.icon),
  .white-text :global(p) {
    color: white !important;
  }
  .text-default :global(h2),
  .text-default :global(h3),
  .text-default :global(h5),
  .text-default :global(a),
  .text-default :global(span),
  .text-default :global(.icon),
  .text-default :global(p) {
    color: var(--grey-dark);
  }
  .reverse {
    flex-direction: row-reverse;
  }
  .container-banner-home {
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
  }
  .img-banner {
    max-width: 45%;
    width: 100%;
    cursor: pointer;
  }
  .img-banner-wide {
    width: 100%;
    cursor: pointer;
  }

  .img-banner-mobile {
    padding: 10px 20px;
  }
  .img-banner-mobile h2 {
    font-size: 20px;
  }
  .img-banner-mobile a {
    font-size: 15px;
  }
  .tips {
    z-index: 1;
  }
  .tips :global(.swiper) {
    padding: 1em 0em;
  }
  .tips :global(.swiper-slide) {
    display: inherit;
    width: auto !important;
    max-width: calc(33.2% - 6px);
    transition: 0.4s;
  }
  .tips :global(.swiper-slide:hover) {
    transform: translateY(-2px);
    filter: brightness(1.05);
  }
  :global(.swiper-pagination-bullet-active) {
    opacity: 1 !important;
    width: 30px !important;
    background: var(--accent-dark) !important;
  }
  :global(.swiper-pagination-bullet) {
    width: 12px;
    height: 6px;
    display: inline-block;
    border-radius: 10px;
    background: var(--background);
    opacity: 0.5;
  }
  .tips__best-sellers {
    background: var(--background-home);
    padding: 4em 0em;
  }
  .tips__unmissable {
    padding: 4em 0em;
    background: linear-gradient(transparent, var(--background-home-2));
  }
  .tips__unmissable h4,
  .tips__best-sellers h4 {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .tips__unmissable img,
  .tips__best-sellers img {
    border-radius: 8px;
    box-shadow: var(--shadow-light);
  }
  .tips__unmissable-card-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #fff5;
    height: 35px;
    width: 35px;
    border-radius: 100px;
  }
  @media only screen and (max-width: 600px) {
    .tips__best-sellers {
      padding: 2em 0em !important;
    }
    .tips__unmissable {
      padding: 2em 1em;
    }
    .padding-h4 {
      padding: 0em 1em;
    }
    .tips :global(.swiper-slide) {
      max-width: calc(100% - 48px) !important;
      margin: 0px 6px;
    }
    .tips :global(.swiper-slide:first-child) {
      margin-left: 20px;
    }
    .tips :global(.swiper-slide:last-child) {
      margin-right: 20px;
    }
    .banner-first-section {
      padding: 1em 0em 0.3em;
    }
    .banner-second-section {
      padding: 0.3em 0em 1em;
    }
    .img-banner {
      max-width: 100%;
      width: 100%;
      cursor: pointer;
      transition: 0.3s;
    }
    .card-product {
      max-width: 180px;
      width: 100%;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
  .cashback {
    background: #60f3d754;
    padding: 0.2em;
  }
  .cashback-amount {
    font-size: 13px;
    font-weight: 500;
  }
  @media only screen and (max-width: 768px) {
    .tips__best-sellers,
    .tips__unmissable {
      padding: 2em 1em;
    }
    .tips :global(.swiper-slide) {
      display: inherit;
      width: auto !important;
      max-width: calc(50% - 6px);
    }
    .reverse {
      flex-direction: column !important;
    }
    .card-product-info {
      padding: 0.6em;
      align-items: center;
    }
  }
`;
