import css from 'styled-jsx/css';

export default css`
  .dropshipping__reseller {
    background: var(--bg-gradient-reseller);
    backdrop-filter: blur(40px);
  }
  .dropshipping__affiliation {
    backdrop-filter: blur(40px);
    background: var(--bg-gradient-affiliation);
  }
  .dropshipping__affiliation,
  .dropshipping__reseller {
    box-shadow: var(--shadow-light);
    border-radius: 6px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    transition: 0.3s;
    cursor: pointer;
    border: solid 2px var(--border-card-home);
  }
  .dropshipping__affiliation:hover,
  .dropshipping__reseller:hover {
    box-shadow: var(--shadow-light-hover);
    background: var(--background-home-2) !important;
    transform: translateY(-4px);
    border: solid 2px #fff4ff;
  }
  .dropshipping__affiliation img,
  .dropshipping__reseller img {
    max-height: 250px;
  }
  .dropshipping__reseller-card,
  .dropshipping__affiliation-card {
    padding: 2em;
    gap: 40px;
    display: flex;
    flex-direction: column;
  }
  .dropshipping__reseller-card h6,
  .dropshipping__affiliation-card h6 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
  .dropshipping__reseller-card span,
  .dropshipping__affiliation-card span {
    background: linear-gradient(261.96deg, #00e4b8 33.88%, #005eff 113.8%);
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .dropshipping {
    padding: 5em 0;
    position: relative;
  }
  .dropshipping:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background-image: url('/images/home/new/bg_prefooter.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .icon-arrow {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    box-shadow: var(--shadow-light);
    background: #fff5;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    .dropshipping {
      padding: 3em 1em;
      position: relative;
    }
    .dropshipping__reseller-card,
    .dropshipping__affiliation-card {
      padding: 1em;
      gap: 16px;
      display: flex;
      flex-direction: column;
    }
    .dropshipping__reseller-card:before {
      background-image: url('./images/home/new/reseller.png');
      position: absolute;
      top: 0px;
      content: '';
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.8;
      background-size: 100px;
      background-repeat: no-repeat;
      background-position: right bottom;
    }
    .dropshipping__affiliation-card:before {
      background-image: url('./images/home/new/affiliation.png');
      position: absolute;
      top: 0px;
      content: '';
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.8;
      background-size: 100px;
      background-repeat: no-repeat;
      background-position: right bottom;
    }
    .icon-arrow {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: var(--shadow-light);
      background: #fff5;
      backdrop-filter: blur(10px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .dropshipping__reseller-card h6,
    .dropshipping__affiliation-card h6 {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding-right: 60px;
    }
    .dropshipping__affiliation-card img {
      max-width: 150px;
    }
  }
`;
