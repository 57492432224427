import React from 'react';
import { useSelector } from 'react-redux';
import style from './index.style';
import BannerSection from './Banner';

export default function BannersHome() {
  const PERMISSIONS = useSelector((state) => state.permissions);
  const _banners =
    useSelector((state) => state.setting)?.find(
      (setting) => setting.type === 'ProductsCarousel'
    )?.body?.banners || [];

  const banners = Object.entries(_banners)
    ?.map((banner) => banner?.[1])
    ?.filter((banner) => {
      let endDate = new Date(
        banner?.endDate.toString().split('.')[0] + banner?.timeZone
      ).getTime();
      let startDate = new Date(
        banner?.startDate.toString().split('.')[0] + banner?.timeZone
      ).getTime();
      let now = new Date().getTime();
      if (PERMISSIONS.IS_ADMIN) {
        return banner;
      } else {
        return now >= startDate && now < endDate && banner?.status === true;
      }
    })
    .sort((a, b) => {
      if (parseInt(a.priority) > parseInt(b.priority)) return -1;
      if (parseInt(a.priority) < parseInt(b.priority)) return 1;
      return 0;
    });

  return (
    <div className={'tips relative'}>
      <div>
        {banners?.map((banner, index) => (
          <BannerSection banner={banner} key={index} />
        ))}
      </div>
      <style jsx>{style}</style>
    </div>
  );
}
