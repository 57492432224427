import css from 'styled-jsx/css';

export default css`
  .wrapper-timer {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
  }
  .wrapper-timer-mobile {
    position: absolute;
    left: 50%;
    top: 35px;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
  }
  .time {
    background: #0008;
    backdrop-filter: blur(5px);
    border: solid thin #fff6;
    width: 60px;
    height: 60px;
    font-size: 22px;
    border-radius: 4px;
    text-shadow: 0px 1px 5px rgba(0, 0, 0);
    font-weight: 500;
  }
  .wrapper-timer-mobile .time {
    background: #0008;
    backdrop-filter: blur(5px);
    border: solid thin #fff6;
    width: 50px;
    height: 50px;
    font-size: 18px;
    border-radius: 4px;
    text-shadow: 0px 1px 5px rgba(0, 0, 0);
    font-weight: 500;
  }
  .time span {
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    color: white;
  }
  .wrapper-timer-mobile .time span {
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-transform: initial;
    color: white;
  }
`;
