import Home from 'components/home';
import Main from '../layouts/main';
import { useSelector } from 'react-redux';
import Dashboard from '../layouts/dashboard';
import Head from 'next/head';
import React, { useMemo } from 'react';
import useTranslation from '../hooks/useTranslation';
import Reseller from '../components/reseller';

function Page() {
  const { locale, TRANSLATIONS } = useTranslation();
  const isLoggedIn = useSelector((s) => s.isLoggedIn);

  const titleHome = useMemo(() => {
    switch (locale) {
      case 'it_IT':
        return 'Dropshipping moda firmata a prezzi di ingrosso | BDroppy';
      case 'en_US':
        return 'Branded Fashion Dropshipping at Wholesale Prices | BDroppy';
      case 'fr_FR':
        return 'Dropshipping de mode de marque à des prix de gros | BDroppy';
      case 'es_ES':
        return 'Dropshipping de moda de marca a precios al por mayor | BDroppy';
      default:
        return "Dropshipping di moda di marca a prezzi all'ingrosso | BDroppy";
    }
  }, [locale]);

  const descriptionHome = useMemo(() => {
    switch (locale) {
      case 'it_IT':
        return "Oltre 120 marchi di moda firmata in pronta consegna, spedizioni rapide e sicure dall'Italia. Capi originali al 100%, a prezzi competitivi. Scopri il catalogo prodotti.";
      case 'en_US':
        return 'Over 120 designer fashion brands in ready stock, fast and secure shipping from Italy. 100% original items at competitive prices. Explore our product catalog.';
      case 'fr_FR':
        return "Plus de 120 marques de mode de créateurs disponibles en stock immédiat, expéditions rapides et sécurisées depuis l'Italie. Articles 100% originaux à des prix compétitifs. Découvrez notre catalogue de produits.";
      case 'es_ES':
        return 'Más de 120 marcas de moda de diseñador en stock listas para su entrega, envíos rápidos y seguros desde Italia. Artículos 100% originales a precios competitivos. Descubre nuestro catálogo de productos.';
      default:
        return "Oltre 120 marchi di moda di alta qualità disponibili per la consegna immediata, spedizioni veloci e sicure dall'Italia. Prodotti originali al 100%, a prezzi competitivi. Scopri il nostro catalogo.";
    }
  }, [locale]);

  function addProductJsonLd() {
    return {
      __html: `{"@context": "https://schema.org/","@type": "WebSite","name": "BDroppy","image": "https://www.bdroppy.com/BDroppy-logo-light.svg","url" : "https://www.bdroppy.com","description": "${descriptionHome}"}`,
    };
  }

  if (locale === 'en_US' || locale === 'fr_FR' || locale === 'es_ES') {
    return (
      <>
        <Head>
          <title>{TRANSLATIONS.pages.reseller.metaTitle}</title>
          <meta
            name="description"
            content={TRANSLATIONS.pages.reseller.metaDescription}
            key="desc"
          />
          <meta
            property="og:title"
            content={TRANSLATIONS.pages.reseller.metaTitle}
            key={'title'}
          />
          <meta
            property="og:description"
            content={TRANSLATIONS.pages.reseller.metaDescription}
          />
          <meta property="og:type" key="og:type" content="website" />
        </Head>
        {isLoggedIn ? (
          <Dashboard>
            <Reseller />
          </Dashboard>
        ) : (
          <Main>
            <Reseller />
          </Main>
        )}
      </>
    );
  }

  return (
    <>
      <Head>
        <title>{titleHome}</title>
        <meta name="description" content={descriptionHome} key="desc" />
        <meta property="og:title" content={titleHome} key={'title'} />
        <meta property="og:description" content={descriptionHome} />
        <meta
          property="og:image"
          key="og:image"
          content={`${process.env.DASHBOARD_URL}/BDroppy-logo-light.svg`}
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta name="google" content="nositelinkssearchbox" key="sitelinks" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={addProductJsonLd()}
          key="product-jsonld"
        />
      </Head>
      {isLoggedIn ? (
        <Dashboard>
          <Home />
        </Dashboard>
      ) : (
        <Main>
          <Home />
        </Main>
      )}
    </>
  );
}

export default Page;
