import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import style from './index.style';
import { useSelector } from 'react-redux';
import {
  Autoplay,
  FreeMode,
  HashNavigation,
  Navigation,
  Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getFileAddress } from '../../../tools/utils';
import Icon from '../../utils/Icon';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function Category() {
  const { TRANSLATIONS } = useTranslation();
  const { isMobile } = useResponsive();
  const router = useRouterWithLang();
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];
  const PERMISSIONS = useSelector((state) => state.permissions);
  const tags = [
    {
      field: 'category',
      value: 'clothing',
      img: '/images/home/category/new-home/abbigliamento.jpg',
      label: TRANSLATIONS.home.category_1,
      url: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&promo=false&category=clothing`
        : '/product?availability=1&promo=false&category=clothing',
    },
    {
      field: 'category',
      value: 'bags',
      img: '/images/home/category/new-home/borse.jpg',
      label: TRANSLATIONS.home.category_2,
      url: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&promo=false&category=bags`
        : '/product?availability=1&promo=false&category=bags',
    },
    {
      field: 'category',
      value: 'shoes',
      img: '/images/home/category/new-home/scarpe.jpg',
      label: TRANSLATIONS.home.category_3,
      url: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&promo=false&category=shoes`
        : '/product?availability=1&promo=false&category=shoes',
    },
    {
      field: 'category',
      value: 'accessories',
      img: '/images/home/category/new-home/accessori.jpg',
      label: TRANSLATIONS.home.category_4,
      url: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&promo=false&category=accessories`
        : '/product?availability=1&promo=false&category=accessories',
    },
    {
      field: 'category',
      value: 'underwear',
      img: '/images/home/category/new-home/intimo.jpg',
      label: TRANSLATIONS.home.category_5,
      url: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&promo=false&category=underwear`
        : '/product?availability=1&promo=false&category=underwear',
    },
  ];

  return (
    <div className="category">
      <div className={'home-card'}>
        <h4 className={isMobile && 'padding-h4'}>
          {TRANSLATIONS.home.productCatalog}
        </h4>
        {isMobile ? (
          <Swiper
            slidesPerView={'auto'}
            slidesPerGroupSkip={1}
            slidesPerGroup={2}
            loopFillGroupWithBlank={true}
            freeMode={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            modules={[
              Pagination,
              Navigation,
              Autoplay,
              HashNavigation,
              FreeMode,
            ]}
          >
            {tags.map((tag, index) => (
              <SwiperSlide
                key={tag?.src}
                index={index}
                className="carousel-slide"
              >
                <img
                  className="slider-image"
                  src={tag.img ? tag.img : getFileAddress(tag.img)}
                  alt={tag?.alt}
                  onClick={() => router.push(tag?.url)}
                />
                <div className={'content-mobile d-f jc-sb w-100 ai-c'}>
                  <span>{tag?.label}</span>
                  <div>
                    <Icon type={'arrow-right'} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="category__wrapper d-f g-20">
            {tags.map((tag, key) => (
              <div
                key={tag.label}
                className={'category__wrapper-card'}
                onClick={() => router.push(tag?.url)}
              >
                <div key={key}>
                  <img src={tag?.img} alt={'alt banner'} />
                </div>
                <div className={'content d-f jc-sb w-100 ai-c'}>
                  <span>{tag?.label}</span>
                  <div>
                    <Icon type={'arrow-right'} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <style jsx>{style}</style>
    </div>
  );
}
