import css from 'styled-jsx/css';

export default css`
  .category {
    padding: 4em 0;
    gap: 20px;
    background: var(--background-home-2);
  }
  .category :global(.swiper) {
    padding-bottom: 3em;
  }
  .category :global(.swiper-slide) {
    max-width: calc(50% - 30px);
    margin: 0px 6px;
  }
  .category :global(.swiper-slide:first-child) {
    margin-left: 20px;
  }
  .category :global(.swiper-slide:last-child) {
    margin-right: 20px;
  }
  .category__wrapper-card img {
    border-radius: 6px;
    cursor: pointer;
  }
  .category__wrapper-card {
    position: relative;
    transition: 0.4s ease-out;
    overflow: hidden;
    border-radius: 6px;
  }
  .category__wrapper-card img {
    transition: 0.5s;
  }
  .category__wrapper-card:hover img {
    transform: scale(1.1);
    filter: brightness(1.1);
  }
  .category__wrapper-card:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 25%;
    border-radius: 0px 0px 6px 6px;
    background: #fff3;
    backdrop-filter: saturate(180%) blur(20px);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  .category__wrapper-card:hover:before {
    opacity: 1;
  }
  .category__wrapper-card:hover .content {
    transform: translateY(0px);
    opacity: 1;
  }
  .content {
    position: absolute;
    z-index: 2;
    color: var(--grey-dark);
    opacity: 0;
    transform: translateY(30px);
    bottom: 0;
    transition: 0.5s;
    padding: 1em;
    cursor: pointer;
  }
  .content > div {
    width: 35px;
    height: 35px;
    background: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content span {
    font-size: 18px;
    font-weight: 600;
  }
  .category :global(.carousel-slide img) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
  @media only screen and (max-width: 768px) {
    .category {
      padding: 2em 0;
    }
  }
  .padding-h4 {
    padding: 0em 1em;
  }
  .category h4 {
    font-weight: 600;
  }

  .content-mobile {
    position: absolute;
    bottom: 0;
    padding: 10px;
    background: white;
    border-radius: 0px 0px 6px 6px;
    color: black !important;
    box-shadow: var(--shadow-light);
    font-size: 12px;
  }
`;
